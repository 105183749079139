export interface Platform {
  displayName: string;
  systemName: string;
}

export interface PlatformUpdateModel {
  platform: string;
  customPlatform?: string;
}

export const bigCommerce: Platform = {
  displayName: "Big Commerce",
  systemName: "big_commerce",
};

export const boldCommerce: Platform = {
  displayName: "Bold Commerce",
  systemName: "bold_commerce",
};

export const custom: Platform = {
  displayName: "Custom",
  systemName: "custom",
};

export const magento1: Platform = {
  displayName: "Magento 1.x",
  systemName: "magento_1",
};

export const magento2: Platform = {
  displayName: "Magento 2.x",
  systemName: "magento_2",
};

export const oracleAtg: Platform = {
  displayName: "Oracle ATG",
  systemName: "oracle_atg",
};

export const salesforceCommerceCloud: Platform = {
  displayName: "Salesforce Commerce Cloud",
  systemName: "salesforce_commerce_cloud",
};

export const shopify: Platform = {
  displayName: "Shopify",
  systemName: "shopify",
};

export const shopifyCartHook: Platform = {
  displayName: "CartHook",
  systemName: "shopify_-_carthook",
};

export const spree: Platform = {
  displayName: "Spree",
  systemName: "spree",
};

export const sylius: Platform = {
  displayName: "Sylius",
  systemName: "sylius",
};

export const virtualCheckout: Platform = {
  displayName: "Virtual Checkout",
  systemName: "virtual_checkout",
};

export const workarea: Platform = {
  displayName: "Workarea",
  systemName: "workarea",
};

export const woocommerce: Platform = {
  displayName: "WooCommerce",
  systemName: "woocommerce",
};

export const ALL_PLATFORMS: Platform[] = [
  bigCommerce,
  boldCommerce,
  custom,
  magento1,
  magento2,
  oracleAtg,
  salesforceCommerceCloud,
  shopify,
  shopifyCartHook,
  spree,
  sylius,
  workarea,
  woocommerce,
];

export const SUPPORTED_PLATFORMS: Platform[] = [
  custom,
  magento1,
  magento2,
  shopify,
  woocommerce,
  bigCommerce,
];